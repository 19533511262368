<template>
    <header>
        <span class="btn-nav" :class="{'is-big':navBig}" @click="toggleNav"><span></span></span>
        <router-link to="/dashboard"><img src="/assets/images/logos/db_rz.svg" alt="Realisierungszentrum & Deutsche Bahn – Logos"></router-link>

        <ul class="nav-top">
            <li class="logout" @click="logout"><svg class="icon"><use xlink:href="#logout"></use></svg></li>
            <search/>
            <li class="filemanager"><router-link to="/webapp/files"><svg class="icon"><use xlink:href="#filemanager"></use></svg></router-link></li>
            <notifications />
        </ul>
    </header>
</template>

<script>
    import { mapGetters } from "vuex";
    import Notifications from '../notifications/Layer'
    import Search from '../search/Layer'

    export default {
        data() {
            return {
            }
        },
        components: {
            Notifications,
            Search
        },
        computed: {
            ...mapGetters({
                navBig: 'config/navBig',
                notifications: 'notifications/getAll',
                events: 'notifications/getEvents'
            })
        },
        methods: {
            toggleNav(){
                if(window.innerWidth < 960) {
                    // this.$store.commit('config/TOGGLE_NAV', !this.navOpen);
                }
                this.$store.commit('config/CHANGE_NAV_LEVEL', 1);
                this.$store.commit('config/SWITCH_NAV_SIZE', !this.navBig);
            },
            logout(){
                this.$store.dispatch('auth/logout');
            }
        },
        mounted(){
            this.$store.commit('config/INIT_CONFIG');

            if(!this.notifications.length)
                this.$store.dispatch('notifications/initAll');

            if(!this.events.length)
                this.$store.dispatch('notifications/initEvents');
        }
    };
</script>
