<template>
    <nav :class="[navBig ? 'is-big':'', 'is-level'+navLevel]">
        <div class="level-1">
            <ul>
                <nav-level2
                    v-for="(item, index) in navigation"
                    :key="'nav'+index"
                    :item="item"
                    :subnav="item.children"
                    :index="index"
                />
                <li class="links">
                    <span class="logout" @click="logout"><svg class="icon"><use xlink:href="#logout"></use></svg></span>
                    <router-link to="/hilfe" class="help-link"><svg class="icon"><use xlink:href="#help"></use></svg> <span>Hilfe</span></router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import NavLevel2 from './NavLevel2'
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters({
                navBig: 'config/navBig',
                navigation: 'content/getNav',
                navLevel: "config/navLevel"
            })
        },
        components: {
            NavLevel2
        },
        methods: {
            logout(){
                this.$store.dispatch('auth/logout');
            }
        },
    };
</script>
