import { RedirectService } from "../../../services/redirect.service";
import { axiosBlob } from "@/services/axios.service";

const getZIP = (payload) => {
    console.log('%cAction'+'%c – get files ZIP!', 'color: #bada55', 'color: white');

    axiosBlob.post("/webapp/files/collection", payload)
        .then(response => {
           const url = window.URL.createObjectURL(new Blob([response.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', 'Archiv.zip');
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
        })
        .catch(error => {
            if(error.response.status === 401 && !RedirectService.redirecting) {
                RedirectService.login()
            }
        });
};


export default {
    getZIP
}
