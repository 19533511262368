import getters from './getters';
import mutations from './mutations';

// initial state
const state = {
    config: {
        navBig: false,
        navOpen: false,
        navLevel: 1
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
