<template>
    <ul class="breadcrumb">
        <li v-if="navItemOne.title"><router-link :to="navItemOne.alias">{{ navItemOne.title }}</router-link><svg class="icon arrow"><use xlink:href="#angle-right"></use></svg></li>
        <li v-if="navItemTwo && navItemTwo.title && (navItemTwo.title !== pageName)"><router-link :to="navItemTwo.alias">{{ navItemTwo.title }}</router-link><svg class="icon arrow"><use xlink:href="#angle-right"></use></svg></li>
        <li><span>{{ pageName }}</span></li>
    </ul>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'breadcrumb',
        props: ['pageName','nav'],
        computed:{
            ...mapGetters({
                navItemOne: "content/getnavLevelOne",
                navItemTwo: "content/getnavLevelTwo"
            }),
        },
        methods: {}
    };
</script>

