import router from '../../../router'
import { RedirectService } from "../../../services/redirect.service";
import { axiosFormData, axiosBlob } from "@/services/axios.service";
// import data from '../../../_assets/mocks/data.js'


// check if user is logged in
const testData = ({ commit }) => {
    console.log('%cAction'+'%c – get navigation data!', 'color: #bada55', 'color: white');

    axiosFormData.get("/?_format=webapp_json&flat")
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                commit('SET_NAV', response.data.menus.main);
                router.push('/dashboard');
            }
        })
        .catch(error => {
            console.log('%cError'+'%c – not logged in! Stay on login page.', 'color: #d40000', 'color: white');
            console.log(error.response);
        });

    // commit('SET_NAV', data.menus.main);
};

const initData = ({ commit }) => {
    console.log('%cAction'+'%c – get navigation data!', 'color: #bada55', 'color: white');

    axiosFormData.get("/?_format=webapp_json&flat")
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                commit('SET_NAV', response.data.menus.main);
            }
        })
        .catch(error => {
            if(error.response.status === 401 && !RedirectService.redirecting) {
                RedirectService.login()
            }
        });

    // commit('SET_NAV', data.menus.main);
};

const initContent = ({ commit, rootGetters }, route) => {
    console.log('%cAction'+'%c – get page content!', 'color: #bada55', 'color: white');

    commit('SWITCH_LOADER', true);

    axiosFormData.get(route+"?_format=webapp_json&flat")
        .then(response => {
            if(response.status === 200) {
                if(Array.isArray(response.data)){
                    commit('CHANGE_TYPE', 'files');
                    commit('CHANGE_CONTENT_FILES', response.data);
                    commit('SET_PAGENAME', response.data.title);

                    setTimeout(function(){
                        commit('SWITCH_LOADER', false);
                    }, 1000);
                }else{
                    commit('CHANGE_TYPE', response.data.type);
                    if(response.data.type === 'files'){
                        commit('CHANGE_CONTENT_FILES', response.data.paragraph);
                    }else{
                        // assign content to store
                        commit('CHANGE_CONTENT', response.data.paragraph);

                        // add last revision date
                        commit('CHANGE_REVISION', response.data.changed);
                    }
                    commit('SET_PAGENAME', response.data.title);

                    setTimeout(function(){
                        commit('SWITCH_LOADER', false);
                    }, 1000);
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401 && rootGetters['auth/authenticated'])
                router.push('/dashboard');

            if((error.response.status === 404 || error.response.status === 403) && rootGetters['auth/authenticated'])
                router.push('/dashboard');
        });

    // console.log(rootGetters);
    // console.log(route);
    //
    // commit('CHANGE_CONTENT', data.paragraph);
    // commit('SET_PAGENAME', data.title);
    // commit('CHANGE_TYPE', 'content_page');
    //
    // setTimeout(function(){
    //     commit('SWITCH_LOADER', false);
    // }, 1000);
};

const getParticipantCSV = (department) => {
    console.log('%cAction'+'%c – get CSV for department!', 'color: #bada55', 'color: white');

    // console.log(department);

    let params = "?csv",
        filename = "Projektbeteiligtenliste";

    if(department) {
        let dept = encodeURI(department);
        filename = department.replaceAll(' ', '');

        params = "?department="+dept+"&csv";
    }

    axiosBlob.get("/webapp/participants"+params)
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename+'.csv');
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch(error => {
            console.log(error);
        });
};

export default {
    testData,
    initData,
    initContent,
    getParticipantCSV
}
