import axios from 'axios';

const axiosFormData = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    headers: {
        'Accept': 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'multipart/form-data',
        'X-Requested-With':'webapp'
    }
})

const axiosBlob = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    headers: {
        'Accept': 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'multipart/form-data',
        'X-Requested-With':'webapp'
    },
    responseType: 'blob'
})

const axiosJSON = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'webapp'
    }
})

export { axiosFormData, axiosJSON, axiosBlob }
