import { RedirectService } from '../../../services/redirect.service';
import { axiosFormData } from '../../../services/axios.service';

const initAll = ({ commit }) => {
    console.log('%cAction'+'%c – get notifications!', 'color: #bada55', 'color: white');

    axiosFormData.get("/notifications?_format=webapp_json&flat")
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                console.log(response);

                commit('INIT_ALL', response.data.paragraph);
            }
        })
        .catch(error => {
            if(error.response.status === 401 && !RedirectService.redirecting) {
                RedirectService.login()
            }
        });
};

const initEvents = ({ commit }) => {
    console.log('%cAction'+'%c – get events!', 'color: #bada55', 'color: white');

    axiosFormData.get("/events?_format=webapp_json&flat")
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                console.log(response);

                commit('INIT_EVENTS', response.data.paragraph);
            }
        })
        .catch(error => {
            if(error.response.status === 401 && !RedirectService.redirecting) {
                RedirectService.login()
            }
        });
};

export default {
    initAll,
    initEvents
}
