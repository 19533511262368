<template>
    <div id="app" :class="{'is-register': this.$route.path === '/register'}">
        <router-view name="header"/>
        <main :class="{'main':$route.name !== 'Register' && $route.name !== 'Login' && $route.name !== 'Reset Password' && $route.name !== 'New Password'}">
            <router-view name="navigation"/>
            <router-view />
        </main>
    </div>
</template>

<style lang="scss">
    @import '_assets/_scss/main';
</style>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: 'app',
        data() {
            return {}
        },
        computed:{
            ...mapGetters({
                navigation: 'content/getNav'
            }),
        },
        mounted(){
            // if(!this.navigation.length)
            //     this.$store.dispatch('content/initData');

            // console.log(this.$route.path);
        }
    }
</script>
