import router from '../../../router'
import { axiosFormData, axiosJSON } from '../../../services/axios.service';

const preRegister = ({ commit, dispatch }, payload) => {
    console.log('%cAction'+'%c – init preregistration!', 'color: #bada55', 'color: white');

    // clear errors and start authentication
    commit('clearError');
    commit('authProgress', true);

    axiosJSON.get("/webapp/register")
        .then(response => {
            if(response.status === 200) {

                const data = {
                    _csrf: response.data._csrf,
                    ...payload
                };

                dispatch('register', data);
            }
        })
        .catch(error => {
            console.log(error);
        });
};

const register = async ({ commit }, data) => {
    console.log('%cAction'+'%c – perform real registration!', 'color: #bada55', 'color: white');

    let formData = new FormData();

    formData.append('firstname', data.firstname);
    formData.append('lastname', data.lastname);
    formData.append('email', data.email);
    formData.append('position', data.position);
    formData.append('password', data.password);
    formData.append('repassword', data.password);
    formData.append('_csrf', data._csrf);

    if(data.phone) formData.append('phone', data.phone);
    if(data.mobile) formData.append('mobile', data.mobile);

    axiosFormData.post("/webapp/register", formData)
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                commit('clearError');
                commit('registerSuccess');
            }
        })
        .catch(error => {
            console.log('%cError'+'%c – Registration failed!', 'color: #d40000', 'color: white');
            commit('registerError', error.response.status);
        });
};

const preLogin = ({ commit, dispatch }, payload) => {
    console.log('%cAction'+'%c – init prelogin!', 'color: #bada55', 'color: white');

    // clear errors and start authentication
    commit('clearError');
    commit('authProgress', true);

    axiosJSON.get("/webapp/login", {
        username: payload.name,
        password: payload.password
    })
        .then(response => {
            if(response.status === 200) {

                const data = {
                    username: payload.name,
                    password: payload.password,
                    _csrf: response.data._csrf
                };

                dispatch('login', data);
            }
        })
        .catch(error => {
            console.log(error);
        });
};

const login = ({ commit }, data) => {
    console.log('%cAction'+'%c – perform real login!', 'color: #bada55', 'color: white');

    let formData = new FormData();

    formData.append('username', data.username);
    formData.append('password', data.password);
    formData.append('_csrf', data._csrf);

    axiosFormData.post("/webapp/login", formData)
        .then(response => {
            if(response.status === 200) {
                commit('clearError');
                commit('authenticated', true);

                if(router.currentRoute.query.redirect) {
                    router.push(router.currentRoute.query.redirect)
                }else{
                    router.push('/dashboard');
                }
            }
        })
        .catch(error => {
            console.log('%cError'+'%c – Login failed!', 'color: #d40000', 'color: white');

            commit('loginError', error.response.status);
        });
};

const logout = ({ commit }) => {

    axiosFormData.post("/webapp/logout")
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                commit('content/CLEAR_STATE', null, { root: true });
                router.push('/login');
            }
        })
        .catch(error => {
            console.log(error);
        });
};


// password handling
const prePasswordReset = async ({ commit, dispatch }, payload) => {
    console.log('%cAction'+'%c – init prePasswordReset!', 'color: #bada55', 'color: white');

    // clear errors and start authentication
    commit('clearError');
    commit('authProgress', true);

    axiosJSON.get("/webapp/reset")
        .then(response => {
            if(response.status === 200) {

                const data = {
                    _csrf: response.data._csrf,
                    ...payload
                };

                dispatch('resetPassword', data);
            }
        })
        .catch(error => {
            console.log(error);
        });
};

const resetPassword = async ({ commit }, data) => {

    let formData = new FormData();

    formData.append('username', data.username);
    formData.append('_csrf', data._csrf);

    axiosFormData.post("/webapp/reset", formData)
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                commit('clearError');
                commit('resetSuccess');
            }
        })
        .catch(error => {
            console.log('%cError'+'%c – Password Reset failed!', 'color: #d40000', 'color: white');
            commit('resetError', error.response.status);
        });
};

const prePasswordNew = async ({ commit, dispatch }, payload) => {
    console.log('%cAction'+'%c – init prePasswordNew!', 'color: #bada55', 'color: white');

    // clear errors and start authentication
    commit('clearError');
    commit('authProgress', true);

    axiosJSON.get("/webapp/reset?key="+payload.key)
        .then(response => {
            if(response.status === 200) {

                const data = {
                    _csrf: response.data._csrf,
                    key: response.data.key,
                    password: payload.password
                };

                dispatch('newPassword', data);
            }
        })
        .catch(error => {
            console.log(error);
        });
};

const newPassword = async ({ commit }, data) => {

    let formData = new FormData();

    formData.append('_csrf', data._csrf);
    formData.append('key', data.key);
    formData.append('password', data.password);

    axiosFormData.post("/webapp/reset", formData)
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                commit('clearError');
                commit('newSuccess');
            }
        })
        .catch(error => {
            console.log('%cError'+'%c – Password Set failed!', 'color: #d40000', 'color: white');
            commit('newError', error.response.status);
        });
};

export default {
    preRegister,
    register,
    preLogin,
    login,
    logout,
    resetPassword,
    prePasswordReset,
    newPassword,
    prePasswordNew
}
