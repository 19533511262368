import router from "../../../router";
import {axiosFormData} from "@/services/axios.service";

const performSearch = ({ commit }, data) => {
    console.log('%cAction'+'%c – get search results!', 'color: #bada55', 'color: white');

    commit('SWITCH_LOADER', true);

    axiosFormData.get("/webapp/search-content?search_keys=" + encodeURI(data) + '&limit=500&offset=0')
        .then(response => {
            console.log(response);
            if(response.status === 200 && response.data.results.length) {
                commit('SWITCH_NORESULT', false);
                commit('SAVE_RESULTS', response.data.results);
                commit('SAVE_INFO', response.data.info);
            }else{
                commit('SWITCH_NORESULT', true);
                commit('SAVE_RESULTS', []);
            }

            commit('SWITCH_LOADER', false);
        })
        .catch(error => {
            if(error.response.status === 401)
                router.push('/login');
        });
};

export default {
    performSearch
}
